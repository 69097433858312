import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';
import AuthorMeta from '../../components/blog/author-meta';
import BlogMeta from '../../components/blog/blog-meta';
import Categories from '../../components/blog/categories';
import PostNav from '../../components/blog/post-nav/layout-two';
import PageHeader from '../../components/pageheader';
import SEO from '../../components/seo';
import Heading from '../../components/ui/Heading';
import Section, { Box, Col, Row } from '../../components/ui/Wrapper';
import Footer from '../../containers/Layout/Footer';
import Header from '../../containers/Layout/Header';
import {
  BlogInfo,
  BlogMedia,
  BlogNavigation,
  CategoryBox,
  HeaderMetaWrap,
  SingleBlogWrap,
  Thumbnail,
} from './blog-template.stc';

const BlogTemplate = ({ data, pageContext, location, ...restProps }) => {
  const post = data.contentfulBlogPost;
  const { next = {}, previous = {} } = pageContext;

  const { sectionStyle, titleStyle, headerMetaStyle } = restProps;

  return (
    <>
      <Header />
      <SEO title={post.title} />
      <PageHeader pageContext={pageContext} location={location} title="Blog" />
      <main className="site-wrapper-reveal">
        <Section {...sectionStyle}>
          <Row>
            <Col lg={12}>
              <BlogMedia>
                <Thumbnail>
                  <Img fluid={post.coverImage.fluid} alt={post.title} />
                </Thumbnail>
              </BlogMedia>
            </Col>
          </Row>
          <Row>
            <Col lg={8} ml="auto" mr="auto">
              <SingleBlogWrap>
                <BlogInfo>
                  <CategoryBox>
                    <Categories categories={post.category} />
                  </CategoryBox>
                  <Heading {...titleStyle}>{post.title}</Heading>
                  <HeaderMetaWrap>
                    {post.author && <AuthorMeta {...headerMetaStyle} slug={post.author.slug} name={post.author.name} />}
                    {post.createdAt && <BlogMeta {...headerMetaStyle} text={post.createdAt} icon={<FaCalendarAlt />} />}
                  </HeaderMetaWrap>
                </BlogInfo>
                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: post.content.childContentfulRichText.html }}
                />
                <Box as="footer">
                  <BlogNavigation>
                    {previous && <PostNav rel="prev" path={`blog/${previous.slug}`} title={previous.title} />}
                    {next && <PostNav rel="next" path={`blog/${next.slug}`} title={next.title} />}
                  </BlogNavigation>
                </Box>
              </SingleBlogWrap>
            </Col>
          </Row>
        </Section>
      </main>
      <Footer />
    </>
  );
};

BlogTemplate.propTypes = {
  sectionStyle: PropTypes.object,
};

BlogTemplate.defaultProps = {
  sectionStyle: {
    pt: '120px',
    pb: '120px',
    responsive: {
      large: {
        pt: '100px',
        pb: '100px',
      },
      medium: {
        pt: '80px',
        pb: '80px',
      },
      small: {
        pt: '60px',
        pb: '60px',
        fullwidth: true,
      },
    },
  },
  titleStyle: {
    as: 'h1',
    fontSize: '40px',
    textalign: 'center',
    mb: '15px',
    wordwrap: 'break-word',
    responsive: {
      medium: {
        fontSize: '35px',
      },
      small: {
        fontSize: '25px',
      },
    },
  },
  headerMetaStyle: {
    mt: '10px',
    mr: '20px',
  },
};

export const postQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      category {
        slug
        title
        createdAt
      }
      author {
        name
        slug
      }
      createdAt(formatString: "LL", locale: "pt-BR")
      content {
        childContentfulRichText {
          html
        }
      }
      coverImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default BlogTemplate;
